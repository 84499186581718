import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import AcademieCTA from '../components/CTA/AcademieCTA'

const BlogPostTemplate = ({ content, title, excerpt, path, siteUrl }) => {
  return (
    <>
      <section className="section">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-four-fifths">
              <h1 className="is-uppercase">{title}</h1>
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </div>
          </div>
        </div>
      </section>
      <div className="meer-link has-text-centered">
        <Link to="/academie/">TERUG NAAR ACADEMIE OVERZICHT</Link>
      </div>
      <AcademieCTA title={title} />
    </>
  )
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  title: PropTypes.string,
}

const BlogPost = ({ data, path }) => {
  const { wpPost: post } = data
  const { title, siteUrl } = data.site.siteMetadata

  return (
    <Layout path={path}>
      <Helmet>
        <title>{`${post.title} | Academie | ${title}`}</title>
        <meta name="description" content={post.meta.metaDesc} />
        <meta name="og:description" content={post.meta.metaDesc} />
      </Helmet>
      <BlogPostTemplate
        content={post.content}
        title={post.title}
        excerpt={post.meta.metaDesc}
        path={path}
        siteUrl={siteUrl}
      />
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  fragment PostFields on WpPost {
    id
    slug
    content
    date(formatString: "MMMM DD, YYYY")
    title
  }
  query BlogPostByID($id: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    wpPost(id: { eq: $id }) {
      id
      title
      slug
      content
      meta {
        metaDesc
      }
    }
  }
`
