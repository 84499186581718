import React from 'react'
import { Link } from 'gatsby'

const AcademieCTA = ({ title }) => {
  const strLeng = title.length - 1
  const lastChar = title[strLeng]

  const titel = lastChar === '?' ? title.substr(0, strLeng) : title

  return (
    <section className="section">
      <div className="container">
        <div className="columns is-centered">
          <div className="shadow bb column is-two-thirds-desktop">
            <div className="has-text-centered p-4">
              Opmerkingen? Vragen over <strong>{titel}</strong>?{' '}
              <Link to="/contact">Contacteer ons!</Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AcademieCTA
